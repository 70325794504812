<template>
    <v-form @submit.prevent="submitResetPasswordForm(resetPasswordForm)">
        <portal to="auth-layout-title">
            Reset Password
        </portal>

        <div class="space-y-default">
            <v-form-group>
                <v-form-label for="password" class="text-white">
                    Password
                </v-form-label>

                <v-form-input v-model="resetPasswordForm.password" type="password" name="password" required :disabled="isLoading" />
            </v-form-group>

            <v-form-group>
                <v-form-label for="password_confirmation" class="text-white">
                    Password Confirmation
                </v-form-label>

                <v-form-input v-model="resetPasswordForm.password_confirmation" type="password" name="password_confirmation" required :disabled="isLoading" />
            </v-form-group>

            <div class="grid grid-cols-6" align="left">
                <div class="col-span-6 text-right flex justify-end">
                    <v-button type="submit" class="w-full justify-center" :disabled="isLoading">
                        Submit
                    </v-button>
                </div>
            </div>
        </div>
    </v-form>
</template>

<script>
export default {
    data() {
        return {
            resetPasswordForm: {
                token: this.$route.query.token,
                email: this.$route.query.email,
                password: null,
                password_confirmation: null,
            },
        };
    },
    methods: {
        submitResetPasswordForm(payload) {
            this.toggleLoading();

            this.$oauth.resetPassword(payload)
                .then((response) => {
                    setTimeout(() => {
                        this.$router.push({ name: "auth.login" });
                        this.$eventBus.$emit("response", response);
                        this.toggleLoading();
                    }, 500);
                })
                .catch((error) => {
                    this.$eventBus.$emit("error", error);
                    this.toggleLoading();

                    if (error.response.status === 423) {
                        this.$router.push({ name: "auth.forgot-password" });
                    }
                });
        },
    },
};
</script>
